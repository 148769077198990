<template>
  <va-card>
    <va-card-title>
      <slot name="title">
        <div>
          {{ $t("products.title") }}
        </div>
      </slot>
    </va-card-title>

    <!-- Table -->
    <va-card-content>
      <va-data-table :no-data-html="$t('product.noResults')" :columns="columns" :items="state.products">
        <template #header(id)>{{ $t("product.id") }}</template>
        <template #header(name)>{{ $t("product.name") }}</template>
        <template #header(type)>{{ $t("product.type") }}</template>

        <template #cell(id)="{ source: id }">
          <router-link :to="{ path: productLink(id) }">
            {{ id }}
          </router-link>
          <button-clipboard-copy :field="$t('product.id')" :value="id" />
        </template>

        <template #cell(name)="{ source: name }">
          <span class="badges">
            {{ name }}
          </span>
        </template>

        <template #cell(type)="{ source: type }">
          {{ type }}
        </template>
      </va-data-table>
    </va-card-content>
    <va-card-content>
      <va-pagination class="pagination" input color="#000000" :hide-on-single-page="true" v-model="state.currentPage"
        :pages="state.totalPages" :page-size="state.perPage" />
    </va-card-content>
  </va-card>
</template>

<style scoped>
.has-tip::after {
  font-family: "Glyphicons Halflings";
  content: "\e085";
  margin-left: 1px;
}

.badges {
  display: flex;
  gap: 5px;
}
</style>

<script setup>
import { reactive, watch, onMounted, inject } from "vue";
import { useI18n } from "vue-i18n";
import { callFetchProducts } from "@/api/licensing";
import ButtonClipboardCopy from "@/components/ButtonClipboardCopy.vue";
import { productLink } from "@/utils/licensing";

const handleError = inject("handleError");
const { t } = useI18n();

const columns = ["id", "name", "type"];

const state = reactive({
  products: [],
  loading: false,
  currentPage: 1,
  totalItems: 0,
  perPage: 10,
  totalPages: 0
});

const props = defineProps({
  params: {
    type: Object
  }
});

/**
 * Create the parameters object for the request and call it.
 * Then calculate the page properties's values.
 * @param {number} page - positive integer
 */
async function loadProducts(page = 1) {
  state.loading = true;

  const params = buildUrlParams(page);

  try {
    const resp = await callFetchProducts({ params });
    if (resp?.data) {
      const products = resp.data.items.map(item => ({
        id: item?.id,
        name: item?.name,
        type: item?.type,
      }));

      state.products = products;

      updatePagination(resp.data.total, page);
    }
  } catch (e) {
    handleError(e);
  }

  state.loading = false;
}

function buildUrlParams(page = 1) {
  const params = new URLSearchParams();

  Object.keys(props.params).forEach(key => {
    if (props.params[key] !== undefined) {
      if (Array.isArray(props.params[key])) {
        props.params[key].forEach(p => params.append(key, p));
      } else {
        params.append(key, props.params[key]);
      }
    }
  });

  params.set("count", state.perPage);
  params.set("page", page);

  return params;
}

function updatePagination(totalItems, page = 1) {
  state.totalItems = totalItems;
  state.currentPage = page;
  state.totalPages =
    Math.floor(totalItems / state.perPage) +
    (totalItems % state.perPage === 0 ? 0 : 1);
}

watch(
  () => state.currentPage,
  async currentPage => {
    await loadProducts(currentPage);
  }
);

watch(
  () => props.params,
  async () => {
    await loadProducts();
  }
);

onMounted(async () => {
  await loadProducts();
});
</script>
